/**
 * Google Tag Manager V2 Provider
 *
 * NOTE: This provider is used to push events to Google Tag Manager. To debug the events,
 * use Adswerve - dataLayer Inspector+ extension for chrome.
 *
 * https://chromewebstore.google.com/detail/adswerve-datalayer-inspec/kmcbdogdandhihllalknlcjfpdjcleom
 */

export class GTMProviderV2 {
  constructor(app) {
    this.provide = app.$gtm
    this.referral_code = app?.$auth?.user?.referral?.code ?? undefined
    this.language = app.i18n?.locale
    this.user = app?.$auth?.user
    this.currency = app?.store?.getters['multiCurrency/get_current_currency_code'] || 'USD'
  }

  typeClearEvent = () => {
    this.provide.push({ type: undefined })
  }

  _prepareEsimItems(payload = [], location = '', discount = null) {
    if (!Array.isArray(payload)) {
      payload = [payload]
    }

    return payload.map((item, index) => {
      if (!location) {
        location = this._esimLocation(item)
      }

      const options = {
        item_id: item?.id,
        item_name: item?.title,
        // coupon: "50_Off",
        discount: 0,
        index: index,
        item_brand: item?.operator?.title,
        item_category: location,
        item_category2: item?.operator?.plan_type,
        item_category3: this._itemCategory3(location, item),
        item_category4: item?.data,
        item_category5: item?.validity,
        // item_list_id: "local_esims_qatar",
        // item_list_name: "Local Esims Qatar",
        price: Number(item?.price?.amount),
        quantity: 1,
      }

      if (item?.promotions) {
        const [promotion] = item.promotions

        if (promotion?.diff?.amount) {
          options.discount = Number(promotion.diff.amount)
        }
      }

      if (discount?.amount) {
        options.discount += Number(discount.amount)
      }

      return options
    })
  }

  _esimType(payload) {
    if (payload?.is_stock === false) {
      return 'out of stock'
    } else if (payload?.promotions?.length > 0) {
      return 'promotional'
    }

    return 'regular'
  }

  _esimLocation(payload) {
    if (payload?.operator?.type === 'global' && payload?.operator?.region?.slug === 'world') {
      return 'global'
    } else if (payload?.operator?.type === 'global') {
      return 'regional'
    }

    return 'country'
  }

  _itemCategory3(location, payload) {
    if (location === 'country') {
      return (payload?.operator?.countries || []).map((country) => country?.title).join(', ')
    }

    return location === 'regional' ? 'region' : location
  }

  _esimCountries(esim) {
    const location = this._esimLocation(esim)

    if (location === 'country') {
      return (esim?.operator?.countries || []).map((country) => country?.title).join(', ')
    }

    return location
  }

  /**
   * App download
   */
  storeClickEvent = (payload) => {
    const options = {
      event: 'app_download',
      click_url: payload.app_store,
    }

    this.provide.push(options)
  }

  /**
   * Search
   */
  searchEvent(payload) {
    let location = 'country'

    if (payload?.slug === 'global') {
      location = 'global'
    } else if (payload?.type === 'region') {
      location = 'regional'
    }

    const options = {
      event: 'search',
      esim_location: location,
      search_term: payload?.slug,
    }

    this.provide.push(options)
  }

  /**
   * Authentication
   */
  signupEvent(payload) {
    const options = {
      event: 'sign_up',
      method: payload.method === 'facebook' ? 'meta' : payload.method,
      referral_code: payload?.referral_code,
      referral_amount: payload?.referral_amount,
      currency: payload?.currency,
      status: payload?.status,
      user_id: payload?.user_id,
      name: payload?.name,
      email: payload?.email,
    }

    this.provide.push(options)
  }

  loginEvent(payload) {
    const options = {
      event: 'login',
      method: payload.method === 'facebook' ? 'meta' : payload.method,
      status: payload.status,
      name: undefined,
      email: undefined,
      user_id: undefined,
      loyalty_level: undefined,
      language: this.language,
      currency: this.currency,
    }

    if (payload.status === 'success' && this.user) {
      options.name = [this.user?.first_name, this.user?.last_name].join(' ')
      options.email = this.user?.email
      options.user_id = this.user?.id
      options.loyalty_level = this.user?.ranking?.code
    }

    this.provide.push(options)
  }

  logoutEvent() {
    const options = {
      event: 'logout',
    }

    this.provide.push(options)
  }

  forgetPassword(payload) {
    const options = {
      event: 'forget_password',
      method: payload.method,
    }

    this.provide.push(options)
  }

  /**
   * My Account interactions
   */
  copyReferralCodeTappedEvent() {
    const options = {
      event: 'my_account',
      account_action: 'copy referral code tapped',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  referralLearnMoreTappedEvent() {
    const options = {
      event: 'my_account',
      account_action: 'referral learn more tapped',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  referralProgramEnrollEvent() {
    const options = {
      event: 'my_account',
      account_action: 'referral program enroll',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  referralShareOptionTappedEvent() {
    const options = {
      event: 'my_account',
      account_action: 'referral share option tapped',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  referralTermsConditionsTappedEvent() {
    const options = {
      event: 'my_account',
      account_action: 'referral terms conditions tapped',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  referAndEarnTappedEvent() {
    const options = {
      event: 'my_account',
      account_action: 'refer and earn tapped',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  referralShowFullHistoryTappedEvent() {
    const options = {
      event: 'my_account',
      account_action: 'referral show full history tapped',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  referralScreenViewedEvent() {
    const options = {
      event: 'my_account',
      account_action: 'referral screen viewed',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  changeFirstName() {
    const options = {
      event: 'my_account',
      account_action: 'change first name',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  changeLastName() {
    const options = {
      event: 'my_account',
      account_action: 'change last name',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  accountChangeEmail() {
    const options = {
      event: 'my_account',
      account_action: 'change email',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  accountChangePassword() {
    const options = {
      event: 'my_account',
      account_action: 'change password',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  accountDeletionRequestSent() {
    const options = {
      event: 'my_account',
      account_action: 'delete account',
      referral_code: this.referral_code,
    }

    this.provide.push(options)
  }

  /**
   * Ecommerce
   */
  viewItem(payload) {
    const options = {
      event: 'view_item',
      ecommerce: {
        currency: this.currency,
        value: Number(payload?.price?.amount),
        items: this._prepareEsimItems(payload),
      },
      referral_code: this.referral_code,
      esim_location: this._esimLocation(payload),
      esim_country: this._esimCountries(payload),
      esim_type: this._esimType(payload),
      esim_details: payload?.title,
    }

    this.provide.push(options)
  }

  viewItemList(payload) {
    let location = 'country'

    if (payload?.listType === 'local-packages') {
      location = 'country'
    } else if (payload?.listType === 'regional-packages') {
      location = 'regional'
    } else if (payload?.listType === 'global') {
      location = 'global'
    }

    const options = {
      event: 'view_item_list',
      ecommerce: {
        currency: this.currency,
        item_list_name: payload.dataType === 'others' ? 'Data' : 'Data/Calls/Texts',
        items: this._prepareEsimItems(payload?.packages, location),
      },
    }

    this.provide.push(options)
  }

  selectItem(payload) {
    let location = 'global'

    if (payload?.listType === 'regional-packages') {
      location = 'regional'
    } else if (payload?.listType === 'local-packages') {
      location = 'country'
    }

    const options = {
      event: 'select_item',
      ecommerce: {
        currency: this.currency,
        value: Number(payload?.item?.price?.amount),
        items: this._prepareEsimItems(payload?.item),
      },
      referral_code: this.referral_code,
      esim_location: location,
      esim_country:
        location === 'country'
          ? (payload?.item?.operator?.countries || []).map((country) => country?.title).join(', ')
          : location,
      esim_type: this._esimType(payload?.item),
      esim_details: payload?.item?.title,
    }

    this.provide.push(options)
  }

  beginCheckout(payload) {
    const options = {
      event: 'begin_checkout',
      ecommerce: {
        currency: this.currency,
        value: Number(payload?.package?.price.amount),
        coupon: undefined,
        items: this._prepareEsimItems(payload?.package),
      },
      referral_code: undefined,
      referral_amount: undefined,
      esim_location: this._esimLocation(payload?.package),
      esim_country: this._esimCountries(payload?.package),
      esim_type: this._esimType(payload?.package),
      esim_details: payload?.package?.title,
    }

    if (payload.referrer?.code) {
      options.referral_code = payload.referrer?.code
      options.referral_amount = payload.discount?.amount ? parseFloat(payload.discount.amount) : undefined
    }

    this.provide.push(options)
  }

  addToCart(payload) {
    const options = {
      event: 'add_to_cart',
      ecommerce: {
        currency: this.currency,
        value: Number(payload?.price?.amount),
        items: this._prepareEsimItems(payload),
      },
      referral_code: this.referral_code,
      esim_location: this._esimLocation(payload),
      esim_country: this._esimCountries(payload),
      esim_type: this._esimType(payload),
      esim_details: payload?.title,
    }

    this.provide.push(options)
  }

  addPaymentInfo(payload) {
    let country = (payload?.package?.operator?.countries || []).map((country) => country?.title).join(', ')

    if (!country && payload?.package?.operator?.type === 'global') {
      country = 'Global'
    }

    const options = {
      event: 'add_payment_info',
      ecommerce: {
        currency: this.currency,
        value: Number(payload?.package?.price?.amount),
        coupon: payload.coupon || undefined,
        payment_type: payload.paymentMethod,
        items: this._prepareEsimItems(payload?.package),
      },
      referral_code: this.referral_code,
      esim_location: this._esimLocation(payload?.package),
      esim_country: country,
      esim_type: this._esimType(payload?.package),
      esim_details: payload?.package?.title,
    }

    this.provide.push(options)
  }

  purchase(payload) {
    let paymentType = payload?.card?.brand

    if (!paymentType) {
      paymentType = payload?.gateway?.title
    }

    const options = {
      event: 'purchase',
      purchase_type: payload?.package?.type === 'topup' ? 'topup' : 'regular',
      first_time_purchase: payload?.is_first_order ?? false,
      customer_status: payload?.is_first_order ? 'new' : 'existing',
      ecommerce: {
        currency: this.currency,
        value: Number(payload?.price?.amount),
        coupon: payload?.coupon?.code || undefined,
        payment_type: (paymentType || '').toLowerCase(),
        items: this._prepareEsimItems(payload?.package, '', payload.discount),
        transaction_id: payload?.code,
      },
      referral_code: undefined,
      referral_amount: undefined,
      esim_location: this._esimLocation(payload?.package),
      esim_country: this._esimCountries(payload?.package),
      esim_type: this._esimType(payload?.package),
      esim_details: payload?.package?.title,
      airmoney_used: Number(payload?.used_airmoney?.amount) || undefined,
      flier_number: payload?.flier_number || undefined,
      // tax: < tax >,
    }

    if (payload?.referrer?.code) {
      options.referral_code = payload?.referrer?.code
      options.referral_amount = payload?.discount ? parseFloat(payload?.discount.amount) : undefined
    }

    this.provide.push(options)
  }

  purchaseFailed(payload) {
    const options = {
      event: 'purchase_failed',
      fail_reason: payload || undefined,
    }

    this.provide.push(options)
  }

  /**
   * My eSIMs Page Interactions
   */
  esimPage(payload) {
    let esimDetails = ''

    if (payload.action === 'top-up') {
      esimDetails = payload?.esim?.title
    } else {
      esimDetails = (payload?.esim?.packages || [])
        .filter((item) => item?.package?.type === 'sim')
        .map((item) => item?.package?.title)
        .join(', ')
    }

    const options = {
      event: 'esim_page',
      esim_action: payload.action,
      referral_code: this.referral_code,
      esim_location: this._esimLocation(payload?.esim),
      esim_type: this._esimType(payload?.esim),
      esim_details: esimDetails,
    }

    this.provide.push(options)
  }

  /**
   * Chat Interactions
   */
  liveChat(payload) {
    const options = {
      event: 'live_chat',
      chat_action: payload,
    }

    this.provide.push(options)
  }

  /**
   * Main Navigation
   */
  mainNavigation(payload) {
    const options = {
      event: 'main_navigation',
      click_text: payload?.text,
      click_url: (payload?.url || '').replace(/\/+$/, ''),
    }

    this.provide.push(options)
  }

  /**
   * Footer Navigation
   */
  footerNavigationV2Event(payload) {
    const options = {
      event: 'footer_navigation',
      click_text: payload?.text,
      click_url: undefined,
    }

    if (payload?.url) {
      options.click_url = payload?.url.replace(/\/+$/, '')
    }

    this.provide.push(options)
  }

  /**
   * Click to Action
   */
  ctaClick(payload) {
    const options = {
      event: 'cta_click',
      click_text: payload?.text,
      click_url: payload?.url,
    }

    this.provide.push(options)
  }

  /**
   * Language Change
   */
  languageChangeV2(payload) {
    const options = {
      event: 'language_change',
      previous_language: payload?.previous_language,
      current_language: payload?.current_language,
    }

    this.provide.push(options)
  }

  /**
   * Loyalty change
   */
  loyaltyChangeV2(payload) {
    const options = {
      event: 'loyalty_change',
      previous_loyalty_level: payload?.previous_loyalty_level || undefined,
      current_loyalty_level: payload?.current_loyalty_level?.code,
      remaining_amount: undefined,
    }

    if (payload?.current_loyalty_level?.next_ranking) {
      const { amount, paid_amount } = payload?.current_loyalty_level?.next_ranking
      if (!amount || !paid_amount) return
      const remainingAmount = parseFloat(amount.amount) - parseFloat(paid_amount.amount)
      if (isNaN(remainingAmount)) return
      options.remaining_amount = parseFloat(remainingAmount.toFixed(2))
    }

    this.provide.push(options)
  }

  renewalsUpdateEvent(payload) {
    const options = {
      event: 'renewal_status',
      ...payload,
    }
    this.provide.push(options)
  }

  /**
   * Currency change
   */
  currencyChange(payload) {
    const options = {
      event: 'currency_change',
      previous_currency: payload.previous_currency,
      current_currency: payload.current_currency,
    }

    this.provide.push(options)
  }

  /**
   * Contact us form
   */
  contactFormV2Event() {
    const options = {
      event: 'generate_lead',
    }

    this.provide.push(options)
  }

  /**
   * eKyc
   */
  eKycEvent({ interaction, esim }) {
    if (typeof interaction !== 'string' || typeof esim !== 'object') {
      return false
    }

    const location = this._esimLocation(esim)
    const options = {
      event: 'kyc',
      kyc_interaction: interaction,
      kyc_type: esim.operator.is_kyc_one_time ? 'one time' : 'regular',
      esim_location: location,
      esim_country: location,
    }

    if (options.esim_country === 'country') {
      options.esim_country = esim.operator?.country?.title || undefined
    }

    this.provide.push(options)

    if (this.debug) {
      console.info('[eKycEvent]', options)
    }
  }

  /**
   * Freemium banner tap
   */
  freemiumBannerTapped() {
    const options = {
      event: 'freemium_banner_tapped',
    }

    this.provide.push(options)
  }

  /**
   * Freemium banner countries selection tap
   */
  validDestinationsTapped() {
    const options = {
      event: 'view_valid_destinations_tapped',
    }

    this.provide.push(options)
  }

  /**
   * eKyc
   */
  eKycEvent({ interaction, esim }) {
    if (typeof interaction !== 'string' || typeof esim !== 'object') {
      return false
    }

    const { is_kyc_one_time, is_operator_kyc, kyc_addr_required } = esim?.operator || {
      is_kyc_one_time: undefined,
      is_operator_kyc: undefined,
      kyc_addr_required: undefined,
    }

    const location = this._esimLocation(esim)
    const isOneTime = !((!is_kyc_one_time && !is_operator_kyc) || (is_kyc_one_time && is_operator_kyc))
    const options = {
      event: 'kyc',
      kyc_interaction: interaction,
      kyc_type: isOneTime ? 'one time' : 'regular',
      esim_location: location,
      esim_country: location,
      destination_address: kyc_addr_required ? 'yes' : 'no',
    }

    if (options.esim_country === 'country') {
      options.esim_country = esim.operator?.country?.title || undefined
    }

    this.provide.push(options)
  }

  joinOrganizationValidateEmail({ status, email, error_message = undefined }) {
    const options = {
      event: 'join_organization_validate_email',
      status,
      email,
      error_message,
    }

    this.provide.push(options)
  }

  joinOrganizationValidatePin({ status, email, error_message = undefined }) {
    const options = {
      event: 'join_organization_validate_pin',
      status,
      email,
      error_message,
    }

    this.provide.push(options)
  }

  joinOrganizationWithoutPinEvent({ status, organization, error = undefined }) {
    const options = {
      event: 'join_organization_without_pin',
      status,
      error_message: error,
      organization_name: organization,
    }

    this.provide.push(options)
  }

  sendInstallationLinkEvent() {
    const options = {
      event: 'install_via_link_tapped',
      platform: 'web',
    }

    this.provide.push(options)
  }
}
